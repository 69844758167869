import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2, SectionText, SectionWrapper } from 'styles/Typography.styled';
import LocationBg from 'images/lokalizacja-bg.png';
import { Markdown } from 'components/Markdown/Markdown';

export const LocationWrapper = styled.div`
    position: relative;

    &:after {
        background: url(${LocationBg});
        background-repeat: no-repeat;
        background-position: right bottom;
        z-index: -1;
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
        bottom: 24px;
        right: 0;
    }
`;

export const LocationSectionWrapper = styled(SectionWrapper)`
    flex-direction: column;
    padding: 160px 0 184px 0;

    ${mediaQueries.md} {
        padding: 72px 80px;
    }

    ${mediaQueries.sm} {
        padding: 48px 28px;
    }
`;

export const Title = styled(H2)`
    margin-bottom: 24px;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 96px;

    ${mediaQueries.sm} {
        margin-left: 0px;
        order: 1;
    }
`;

export const MiddleTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 100px;

    ${mediaQueries.sm} {
        margin-right: 0;
    }
`;

export const Image = styled.img`
    max-width: 500px;
    object-fit: cover;

    ${mediaQueries.sm} {
        order: 2;
        max-width: 80%;
        margin: 40px auto;
    }

    ${mediaQueries.xs} {
        max-width: 100%;
        margin: 0;
    }
`;

export const MarkdownWrapper = styled(Markdown)`
    max-width: 470px;
    margin-bottom: 56px;
    ${mediaQueries.sm} {
        max-width: 100%;
    }
`;

export const MiddleText = styled(SectionText)`
    width: 100%;
    margin-bottom: 56px;
    ${mediaQueries.sm} {
        order: 1;
    }
`;

export const IconsWrapper = styled.div`
    display: flex;
    ${mediaQueries.sm} {
        order: 3;
        margin-top: 34px;
        justify-content: center;
        text-align: center;
    }
`;

export const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 200px;
    width: 100%;
    margin-right: 82px;

    &:last-child {
        margin-right: 0;
    }

    ${mediaQueries.sm} {
        margin-right: 0px;
    }
`;

export const ImageColumnWrapper = styled.div`
    margin-bottom: 32px;
`;

export const ColumnTitle = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.neutral900};
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.s};
            line-height: 24px;
        `,
);

export const UpperWrapper = styled.div`
    display: flex;

    &:first-child {
        margin-bottom: 86px;
    }

    &:last-child {
        justify-content: space-between;
    }

    ${mediaQueries.sm} {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr;
    }

    ${mediaQueries.sm} {
        display: flex;
        flex-direction: column;
    }
`;

export const BottomWrapper = styled(SectionWrapper)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 88px;
    width: 100%;

    ${mediaQueries.md} {
        padding: 0px 80px;
    }

    ${mediaQueries.sm} {
        margin-bottom: 0px;
        padding: 48px 28px;
        flex-direction: column;
    }
`;

export const UpperTitle = styled(H2)`
    margin-right: 54px;
    max-width: 470px;

    ${mediaQueries.sm} {
        margin-bottom: 24px;
        margin-right: 0;
        max-width: unset;
    }

    ${mediaQueries.xs} {
        text-align: center;
    }
`;

export const NumbersWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${mediaQueries.xs} {
        flex-wrap: wrap;
        display: flex;
        gap: 32px 16px;
    }
`;

export const MiddleWrapper = styled.div``;
